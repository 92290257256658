<template>
  <div class="tags">
    <v-slide-x-transition>
      <CyTag
        v-show="!!searchTerm"
        class="ma-1"
        tall
        data-cy="search-tag"
        variant="default"
        :label="$t('searchTerm')">
        {{ searchTerm }}
        <a
          @click="$emit('clear-search')">
          <v-icon size="16">close</v-icon>
        </a>
      </CyTag>
    </v-slide-x-transition>
    <template v-for="tag of tags">
      <slot
        name="tag"
        :tag="tag"
        :remove="remove">
        <CyTag
          v-if="!tag.hide"
          :key="`${tag.key}:${tag.id}`"
          class="ma-1"
          tall
          variant="default"
          data-cy="cr-search-tag"
          :label="tag.label">
          {{ tag.value }}
          <a
            v-if="!tag.persist"
            @click="remove(tag.key, tag.id)">
            <v-icon size="16">close</v-icon>
          </a>
        </CyTag>
      </slot>
    </template>
    <CyMemberTag
      v-for="{ key, member, id } of ownerTags"
      :key="`${key}:${id}`"
      class="ma-1"
      :member="member"
      medium
      @remove="remove(key, id)"/>
  </div>
</template>

<script>
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment
import { mapState, mapGetters, mapActions } from 'vuex'
import CyMemberTag from '@/components/member-tag'

/**
 * Tags component designed to be used in conjunction with the CyDataTableFilters component
 * It will display the current filters in a tags format
 *   *
 * @param {Array}    filters          An array of objects containing the options for each filter component.
 * @param {String}   searchTerm       A search phrase
 */
export default {
  name: 'CyDataTableTags',
  components: {
    CyMemberTag,
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    searchTerm: {
      type: String,
      default: '',
    },
    workingRoute: {
      type: Object,
      default () {
        return this.$route
      },
    },
    hideValues: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    ...mapState('organization', {
      members: (state) => state.available.members,
    }),
    ...mapGetters('organization', [
      'getAvailableById',
    ]),
    ...mapGetters('layout', [
      'getDataTableFilters',
    ]),
    tags () {
      return _.flatMap(this.getDataTableFilters(this.workingRoute.name), (value, key) => {
        const options = this.getOptionsByKey(key)
        const type = this.type(key)
        const types = {
          date: () => ({
            key,
            value,
            label: this.displayDateLabel(key),
            id: value,
          }),
          dateTimeRange: () => ({
            key,
            value: this.displayTimestamp(value),
            label: this.displayDateTimeRangeLabel(key, options?.tagOptions?.translations),
            id: value,
            persist: true,
          }),
          tag: () => _.map(value.split(','), (singleValue) => ({
            key,
            value: singleValue,
            label: key.split('[')[0],
            id: singleValue,
            hide: _.includes(this.hideValues, `${key}:${singleValue}`),
          })),
          select: () => _.map(value.split(','), (singleValue) => ({
            key,
            value: this.displaySelectValue(singleValue),
            label: this.displaySelectLabel(singleValue, options.label),
            id: singleValue,
          })),
          radio: () => ({
            key,
            value: _.find(options.items, ({ value: optionValue }) => _.toNumber(value) === optionValue).title,
            label: options.label,
          }),
          owner: () => [],
        }
        return types[type]()
      })
    },
    ownerTags () {
      return _.flatMap(this.getDataTableFilters(this.workingRoute.name) || [], (value, key) => this.type(key) === 'owner' && !_.$isEmpty(this.members)
        ? _.map(value.split(','), (singleValue) => ({ key, member: this.getAvailableById('members', Number(singleValue)), id: singleValue }))
        : [],
      )
    },
    selectItems () {
      return _.chain(this.filters)
        .filter(['type', 'select'])
        .map((filter) => _.get(filter, 'items', []))
        .flatten()
        .value()
        .map((filter) => ({ ...filter, value: filter.value.toString() }))
    },
  },
  methods: {
    ...mapActions('layout', [
      'REMOVE_DATA_TABLE_FILTER',
    ]),
    getOptionsByKey (key) {
      const keyRootWord = key.split('[')[0]
      return _.find(this.filters, ({ queryParams = [] }) =>
        _.some(queryParams, (queryParam) =>
          queryParam.includes(keyRootWord)))
    },
    type (key) {
      const options = this.getOptionsByKey(key)
      return options?.type || 'tag'
    },
    displayDateLabel (key) {
      return key.includes('[gt]')
        ? this.$t('createdAfter')
        : this.$t('createdBefore')
    },
    displayDateTimeRangeLabel (key, translations) {
      if (_.includes(_.keys(translations), key)) return this.$t(translations[key])
      return _.some(['[gt]', 'begin'], (beginToken) =>
        key.includes(beginToken))
        ? this.$t('forms.field.timeRangeStart')
        : this.$t('forms.field.timeRangeEnd')
    },
    displayTimestamp (value) {
      if (_.isEmpty(value)) throw Error('value must not be empty')
      const parsed = moment(value, moment.ISO_8601, true)
      return parsed.isValid()
        ? parsed.format('YYYY-MM-DD') // eslint-disable-line you-dont-need-momentjs/format
        : moment(Number(value)).format('YYYY-MM-DD') // eslint-disable-line you-dont-need-momentjs/format
    },
    selectItemTitle (value) {
      return _.find(this.selectItems, ['value', value])?.title || ''
    },
    displaySelectValue (value) {
      return this.selectItemTitle(value).split(':').length > 1
        ? this.selectItemTitle(value).split(':')[1]
        : this.selectItemTitle(value)
    },
    displaySelectLabel (value, label) {
      return this.selectItemTitle(value).split(':').length > 1
        ? this.selectItemTitle(value).split(':')[0]
        : label
    },
    remove (type, value) {
      this.REMOVE_DATA_TABLE_FILTER({ name: this.workingRoute.name, filterType: type, filterValue: value })
    },
  },
  i18n: {
    messages: {
      en: {
        createdAfter: 'Created after',
        createdBefore: 'Created before',
        searchTerm: 'Search term',
      },
      es: {
        createdAfter: 'Creado después',
        createdBefore: 'Creado antes',
        searchTerm: 'Término de búsqueda',
      },
      fr: {
        createdAfter: 'Créé après',
        createdBefore: 'Créé avant',
        searchTerm: 'Terme de recherche',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.tags {
  padding: 8px 24px;
  background-color: get-color("white");

  .data-table-inline-placeholder {
    width: 30px;
    height: 30px;
    margin-top: 0;
    line-height: 30px;
  }

  .v-chip {
    font-family: "Roboto Mono", monospace;
    font-size: 13px;
  }

  .search-term {
    height: 27px;
    color: get-color("grey", "dark-3");
  }
}
</style>
