<template>
  <div class="pipelines-overview-page">
    <CyWorkersWarningMessage
      v-if="!hasRunningWorkers"
      class="mb-2"/>

    <div class="pipelines__header px-4">
      <transition
        name="slide-fade-left"
        mode="out-in">
        <CySearchBox
          v-if="isSearchActive"
          ref="headerSearchbar"
          v-model.trim="searchTerm"
          autofocus
          :placeholder="$t('searchBy')"
          append-icon="search"
          clearable
          class="search-field"
          @click:clear="$toggle.isSearchActive(false)"
          @blur="$toggle.isSearchActive(false)"/>
      </transition>

      <transition name="slide-fade-right">
        <div
          v-if="!isSearchActive"
          class="d-flex grow">
          <v-icon
            color="primary"
            class="align-self-center mr-6"
            @click="$toggle.isSearchActive">
            search
          </v-icon>

          <CyDataTableFilters
            class="mx-2"
            :filters="filters">
            <template #item-content="{ item, items }">
              <template v-if="item.key === 'statuses'">
                <v-list-item-avatar>
                  <v-icon :class="item.value">
                    {{ getStatusIcon(item.value) }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title :class="[{ 'font-weight-bold': _.includes(items, item) }]">
                  {{ item.title }}
                </v-list-item-title>
              </template>
            </template>
          </CyDataTableFilters>

          <CyButton
            v-has-rights-to="'UpdatePipeline'"
            class="ml-auto"
            :loading="fetchInProgress"
            variant="secondary"
            theme="secondary"
            icon="sync"
            :visible="!isInitiallyLoading"
            @click="getPipelines">
            {{ $t('forms.btnRefresh') }}
          </CyButton>
        </div>
      </transition>
    </div>

    <CyDataTableTags
      v-if="!_.$isEmpty(activeFilters) || !_.$isEmpty(searchTerm)"
      :filters="filters"
      :search-term="searchTerm"
      class="elevation-1"
      @clear-search="searchTerm = ''">
      <template #tag="{ tag, remove }">
        <template v-if="tag.key !== $static.searchTermQueryParam"/>
        <template v-if="tag.key === 'statuses[in]'">
          <v-chip
            class="status-chip"
            close
            @click:close="remove(tag.key, tag.id)">
            <v-avatar>
              <v-icon :class="tag.id">
                {{ getStatusIcon(tag.id) }}
              </v-icon>
            </v-avatar>
            {{ tag.value }}
          </v-chip>
        </template>
      </template>
    </CyDataTableTags>

    <v-row class="mt-4">
      <v-progress-circular
        v-if="fetchInProgress"
        indeterminate
        color="secondary"
        size="100"
        class="page-loader"/>

      <template v-else>
        <v-col
          v-if="!_.isEmpty(activeFilters) && _.isEmpty(pipelines)"
          cols="12"
          class="text-center mt-6">
          {{ $t('noFilteredPipelines') }}
        </v-col>
        <v-col
          v-else-if="_.isEmpty(pipelines)"
          cols="12"
          class="text-center mt-6">
          {{ $t('noPipelinesAvailable') }}
        </v-col>
        <v-col
          class="pipelines__grid"
          data-cy="pipelines-grid">
          <CyViewsPipelinePreview
            v-for="(pipeline, key) in pipelines"
            :key="key"
            v-has-rights-to="['GetPipeline', pipeline.name]"
            :pipeline="pipeline"/>
        </v-col>
      </template>
    </v-row>

    <div :class="['legend mt-12', { 'legend--expanded': isLegendExpanded }]">
      <div
        class="legend__title d-flex align-center"
        @click="toggleLegend">
        <v-icon
          class="rounded-circle mr-2"
          color="primary">
          {{ legendIcon }}
        </v-icon>
        {{ $t('legend') }}
      </div>

      <div
        v-for="{ status, label, icon } of $static.statuses"
        :key="status"
        class="d-flex legend__option option">
        <dl class="d-flex align-center">
          <dt :class="[status, 'option__blob']">
            <v-icon v-if="icon">
              {{ icon }}
            </v-icon>
          </dt>
          <dd class="primary--text option__label">
            {{ label }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { constructBreadcrumb } from '@/utils/helpers'
import CyDataTableFilters from '@/components/data-table/filters'
import CyDataTableTags from '@/components/data-table/tags.vue'
import CyViewsPipelinePreview from '@/components/views/pipeline-preview'
import CyWorkersWarningMessage from '@/components/workers-warning-message'
import CySearchBox from '@/components/search-box.vue'

const statuses = [
  {
    status: 'started',
    label: 'Running',
    icon: '',
  },
  {
    status: 'succeeded',
    label: 'Succeeded',
    icon: 'check_circle',
  },
  {
    status: 'errored',
    label: 'Errored',
    icon: 'warning',
  },
  {
    status: 'aborted',
    label: 'Aborted',
    icon: 'cancel',
  },
  {
    status: 'paused',
    label: 'Paused',
    icon: 'pause_circle_filled',
  },
  {
    status: 'failed',
    label: 'Failed',
    icon: 'error',
  },
  {
    status: 'pending',
    label: 'Pending',
    icon: 'pending',
  },
]

export default {
  name: 'CyPagePipelinesOverview',
  components: {
    CyDataTableFilters,
    CyDataTableTags,
    CyViewsPipelinePreview,
    CyWorkersWarningMessage,
    CySearchBox,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.pipelinesOverview'), [
      {
        label: this.$t('routes.observabilitySection'),
        name: 'observabilitySection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.observabilitySection'),
      description: {
        text: this.$t('routes.observabilitySectionDescription'),
      },
    }
  },
  data: () => ({
    isLegendExpanded: false,
    isSearchActive: false,
  }),
  computed: {
    ...mapState({
      pipelines: (state) => state.organization.available.pipelines,
      projects: (state) => state.organization.available.projects,
      environments: (state) => state.organization.available.environments,
      fetchInProgress: (state) => state.organization.fetchInProgress.pipelines,
    }),
    ...mapGetters('organization', [
      'hasRunningWorkers',
    ]),
    ...mapGetters('layout', [
      'getDataTableFilters',
    ]),
    $static () {
      return {
        statuses,
        searchTermQueryParam: 'concourse_pipeline_name[rlike]',
      }
    },
    filters () {
      return [
        {
          type: 'select',
          label: this.$t('forms.status'),
          queryParams: ['statuses'],
          items: statuses.map(({ status, label }) => ({
            title: label,
            key: 'statuses',
            value: status,
          })),
        },
        {
          type: 'select',
          label: this.$t('routes.project'),
          queryParams: ['project_canonical'],
          items: this.projects.map(({ name, canonical }) => ({
            title: name,
            key: 'project_canonical',
            value: canonical,
          })),
        },
        {
          type: 'select',
          label: this.$t('Environment'),
          queryParams: ['environment_canonical'],
          items: this.environments.map(({ canonical }) => ({
            title: canonical,
            key: 'environment_canonical',
            value: canonical,
          })),
        },
      ]
    },
    searchTerm: {
      get () {
        return _.get(
          this.getDataTableFilters(this.$route.name),
          this.$static.searchTermQueryParam,
          '',
        )
      },
      set (searchTerm) {
        const { name } = this.$route
        this.SET_DATA_TABLE_FILTERS({
          name,
          filters: {
            ...this.getDataTableFilters(name),
            [this.$static.searchTermQueryParam]: searchTerm,
          },
        })
      },
    },
    activeFilters () {
      return this.getDataTableFilters(this.$route.name)
    },
    legendIcon () {
      return this.isLegendExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
    },
    isInitiallyLoading () {
      return this.fetchInProgress && _.isEmpty(this.pipelines)
    },
  },
  watch: {
    activeFilters: {
      async handler (newFilters, oldFilters) {
        if (!_.isEqual(newFilters, oldFilters) && !this.fetchInProgress) await this.getPipelines()
      },
      deep: true,
    },
  },
  async created () {
    await Promise.all([
      await this.getPipelines(),
      await this.getProjects(),
      await this.getEnvironments(),
    ])
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
    ]),
    async getPipelines () {
      await this.FETCH_AVAILABLE({
        keyPath: 'pipelines',
        extraParams: [this.activeFilters],
      })
    },
    async getProjects () {
      await this.FETCH_AVAILABLE({
        keyPath: 'projects',
      })
    },
    async getEnvironments () {
      await this.FETCH_AVAILABLE({
        keyPath: 'environments',
      })
    },
    toggleLegend () {
      this.isLegendExpanded = !this.isLegendExpanded
    },
    getStatusIcon (status) {
      return _.find(this.$static.statuses, ['status', status])?.icon || ''
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.pipelinesOverview',
        filterBy: 'Filter by',
        legend: 'Legend',
        noFilteredPipelines: 'No pipeline matches the filters',
        noPipelinesAvailable: 'There are no pipelines to show',
        searchBy: 'Search by Pipeline name',
      },
      es: {
        title: '@:routes.pipelinesOverview',
        filterBy: 'Filtrado por',
        legend: 'Leyenda',
        noFilteredPipelines: 'Ninguna pipeline coincide con los filtros',
        noPipelinesAvailable: 'No hay pipelines para mostrar',
        searchBy: 'Buscar por Nombre de pipeline',
      },
      fr: {
        title: '@:routes.pipelinesOverview',
        filterBy: 'Filtrer par',
        legend: 'Légende',
        noFilteredPipelines: 'Aucune pipeline ne correspond aux filtres',
        noPipelinesAvailable: `Il n'y a aucune pipeline à afficher`,
        searchBy: 'Rechercher par Nom de pipeline',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$legend-height: 50px;

.pipelines-overview-page {
  flex-direction: column;
}

.page-loader {
  flex: 1 0 100%;
  height: 50vh !important;

  ::v-deep svg {
    width: 100px;
    height: 100px;
  }
}

.failed { color: get-color("build", "failed"); }
.succeeded { color: get-color("build", "succeeded"); }
.errored { color: get-color("build", "errored"); }
.aborted { color: get-color("build", "aborted"); }
.pending { color: get-color("build", "pending"); }
.paused { color: get-color("build", "paused"); }

.started {
  color: get-color("build", "running");

  @include running-animation(get-color("build", "pending"), get-color("build", "black-faded"));
}

.v-avatar .v-icon.started {
  width: 20px;
  height: 20px;
}

.legend {
  position: fixed;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 196px;
  margin: 0;
  margin-left: auto;
  padding: 0 1em 1em;
  transform: translateY(calc(100% - #{$legend-height}));
  transition: all 0.2s ease-in-out;
  border-top-left-radius: 24px;
  background-color: get-color("grey", "light-5");
  box-shadow: map.get($box-shadow, "normal");
  color: map.get($slate-grey, "main");

  &__title {
    height: $legend-height !important;
    padding: 0;
    transition: all 0.2s ease-in-out;
    border-radius: 50%;
    font-weight: bold;
    cursor: pointer;

    .v-icon {
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      color: map.get($teal, "main");

      .v-icon {
        background-color: map.get($teal, "light-4");
      }
    }
  }

  &__option {
    flex-wrap: nowrap;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    .option {
      &__blob {
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 8px;
        border-radius: 50%;

        .v-icon {
          color: inherit;
        }

        &.succeeded { color: get-color("build", "succeeded"); }
        &.failed { color: get-color("build", "failed"); }
        &.errored { color: get-color("build", "errored"); }
        &.aborted { color: get-color("build", "aborted"); }
        &.paused { color: get-color("build", "paused"); }
        &.pending { color: get-color("build", "pending"); }
      }

      &__label {
        display: contents;
        flex: 1 0 auto;
        font-size: 15px;
      }
    }
  }

  &--expanded {
    transform: translateY(0);
  }
}

.pipelines {
  &__header {
    display: flex;
    align-items: center;
    min-height: 60px;

    &.elevation-1 {
      border-bottom: 1px solid get-color("grey", "light-2");
      border-radius: 4px 4px 0 0;
    }

    ::v-deep {
      > .search-field {
        margin-top: 0;

        .v-input__slot {
          margin-bottom: 0;
        }
      }
    }
  }

  &__grid {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
    column-gap: 32px;
  }
}

.status-chip {
  $size: 27px;

  height: $size;
  margin: 4px;
  padding-right: 10px;
  padding-left: 0;

  .v-avatar {
    width: $size !important;
    min-width: $size !important;
    height: $size !important;
    margin-right: 8px;

    .v-icon {
      width: $size;
      height: $size;

      &.started {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.slide-fade-left {
  &-enter-active {
    transform-origin: left center;
    transition: all 0.6s ease-in;
    animation: slide-in 0.6s;
  }

  &-enter {
    transform: translateX(-20px) !important;
    opacity: 0;
  }

  &-leave,
  &-leave-active {
    transition: none;
  }
}

@keyframes slide-in {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

.slide-fade-right {
  &-enter-active {
    transform-origin: right center;
    transition: all 0.45s ease;
  }

  &-enter {
    transform: translateX(20px) !important;
    opacity: 0;
  }

  &-leave,
  &-leave-active {
    transition: none;
  }
}
</style>
